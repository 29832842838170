import { Outlet } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { managerLayoutDocument } from '@social-garden/api/documents/manager.ts';
import { Stack, Toolbar } from '@mui/material';
import SuspenseFallback from '../components/SuspenseFallback.tsx';
import { removeToken } from '../utils/authToken.ts';
import AppBar from '../components/AppBar.tsx';
import Drawer from '../components/Drawer.tsx';

const toolbarVariant: 'regular' | 'dense' = 'dense';

export default function ManagerLayout() {
  const {
    i18n: { changeLanguage },
  } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { data } = useSuspenseQuery(managerLayoutDocument);

  const handleOpenDrawer = useCallback(() => {
    setMobileOpen(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setMobileOpen(false);
  }, []);

  const isSetupCompleted = useMemo<boolean>(() => {
    if (data.whoAmI.__typename !== 'Manager') {
      return false;
    }
    return Boolean(
      data.whoAmI.company.currentSubscription &&
        data.whoAmI.company.currentSubscription.subscriptionPlan &&
        data.whoAmI.company.currentSubscription.status === 'active' &&
        data.whoAmI.company.brands.length > 0,
    );
  }, [data.whoAmI]);

  const drawerWidth = useMemo(() => {
    if (isSetupCompleted) {
      return 250;
    }
    return 0;
  }, [isSetupCompleted]);

  useEffect(() => {
    (async () => {
      if (data.whoAmI.__typename === 'Manager') {
        await changeLanguage(data.whoAmI.locale);
      } else {
        removeToken();
      }
    })();
  }, [data.whoAmI, changeLanguage]);

  if (data.whoAmI.__typename !== 'Manager') {
    throw new Error('Authenticated user is not a manager!');
  }

  return (
    <Stack flex={1} direction="row">
      <AppBar
        drawerWidth={drawerWidth}
        toolbarVariant={toolbarVariant}
        isSetupCompleted={isSetupCompleted}
        manager={data.whoAmI}
        onOpenDrawerClick={handleOpenDrawer}
      />
      {isSetupCompleted ? (
        <Drawer
          drawerWidth={drawerWidth}
          toolbarVariant={toolbarVariant}
          mobileOpen={mobileOpen}
          onCloseDrawerClick={handleCloseDrawer}
        />
      ) : null}
      <Stack
        flex={1}
        width={{
          md: `calc(100% - ${drawerWidth}px)`,
        }}
        py={{ xs: 2, sm: 3 }}
        component="main">
        <Toolbar variant={toolbarVariant} />
        <Suspense fallback={<SuspenseFallback />}>
          <Outlet context={{ manager: data.whoAmI }} />
        </Suspense>
      </Stack>
    </Stack>
  );
}
