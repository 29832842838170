import { graphql } from '../gql';

export const applyForCampaignDocument = graphql(`
  mutation applyForCampaign($input: ApplyForCampaignInput!) {
    applyForCampaign(input: $input) {
      id
      status
      rewardValue
      campaign {
        id
        creatorHasCampaign {
          id
        }
      }
    }
  }
`);

export const unapplyFromCampaignDocument = graphql(`
  mutation unapplyFromCampaign($creatorHasCampaignId: ID!) {
    unapplyFromCampaign(creatorHasCampaignId: $creatorHasCampaignId)
  }
`);

export const myCreatorHasCampaignsDocument = graphql(`
  query myCreatorHasCampaigns(
    $input: MyCreatorHasCampaignsInput!
    $first: Int!
    $page: Int
  ) {
    myCreatorHasCampaigns(input: $input, first: $first, page: $page) {
      data {
        id
        status
        campaign {
          id
          name
          headerImage {
            uri
            hash
          }
          brand {
            id
            name
            logo {
              uri
              hash
            }
          }
        }
      }
      paginatorInfo {
        hasMorePages
        currentPage
      }
    }
  }
`);

export const submitCampaignGoalDocument = graphql(`
  mutation submitCampaignGoal($input: SubmitCampaignGoalInput!) {
    submitCampaignGoal(input: $input) {
      id
      status
      contentUrl
    }
  }
`);

export const creatorHasCampaignChannelWithCampaignTypeDocument = graphql(`
  query creatorHasCampaignChannelWithCampaignType($id: ID!) {
    creatorHasCampaign(id: $id) {
      id
      channel {
        ...channelWithAccessTokenValid
      }
      campaign {
        id
        includeContentFileOnSubmission
        ...campaignTypeable
      }
    }
  }
`);

export const creatorsOfCampaignDocument = graphql(`
  query creatorsOfCampaign(
    $input: CreatorsOfCampaignInput!
    $first: Int!
    $page: Int
  ) {
    creatorsOfCampaign(input: $input, first: $first, page: $page) {
      data {
        id
        status
        price
        channel {
          ...channel
        }
        creator {
          id
          username
          isUnreliable
          profilePicture {
            uri
            hash
          }
        }
        campaign {
          id
          statistics {
            id
            reviewApplicationBefore
            reviewSubmissionBefore
          }
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`);

export const creatorHasCampaignDocument = graphql(`
  query creatorHasCampaign($id: ID!) {
    creatorHasCampaign(id: $id) {
      id
      price
      status
      reason
      contentUrl
      submitUntil
      rewardValue
      channel {
        ...channel
        channelable {
          ... on InstagramChannel {
            ageFollowerDemographics {
              id
              age
              percentage
            }
            countryFollowerDemographics {
              id
              country
              percentage
            }
            genderFollowerDemographics {
              id
              gender
              percentage
            }
          }
        }
      }
      campaignGiftCodes {
        id
        code
        gift {
          id
          type
          name
        }
      }
      answers {
        id
        answer
        question {
          id
          question
        }
      }
      deliveryAddress {
        id
        recipientName
        streetAddressLine1
        streetAddressLine2
        postcode
        city
        country
      }
      creator {
        id
        username
        description
        isUnreliable
        profilePicture {
          uri
          hash
        }
      }
      campaign {
        id
        name
        includeContentFileOnSubmission
        ...campaignTypeable
        submitUntil
        gifts {
          id
          type
          name
        }
        questions {
          id
          question
        }
      }
    }
  }
`);

export const acceptCampaignApplicationDocument = graphql(`
  mutation acceptCampaignApplication($input: AcceptCampaignApplicationInput!) {
    acceptCampaignApplication(input: $input) {
      id
      status
      submitUntil
      campaignGiftCodes {
        id
        code
        gift {
          id
          type
          name
          description
        }
      }
    }
  }
`);

export const declineCampaignApplicationDocument = graphql(`
  mutation declineCampaignApplication($creatorHasCampaignId: ID!) {
    declineCampaignApplication(creatorHasCampaignId: $creatorHasCampaignId) {
      id
      status
    }
  }
`);

export const acceptCampaignGoalSubmissionDocument = graphql(`
  mutation acceptCampaignGoalSubmission($creatorHasCampaignId: ID!) {
    acceptCampaignGoalSubmission(creatorHasCampaignId: $creatorHasCampaignId) {
      id
      status
    }
  }
`);

export const retryCampaignGoalSubmissionDocument = graphql(`
  mutation retryCampaignGoalSubmission(
    $input: RetryCampaignGoalSubmissionInput!
  ) {
    retryCampaignGoalSubmission(input: $input) {
      id
      status
      submitUntil
      reason
    }
  }
`);

export const failCampaignGoalSubmissionDocument = graphql(`
  mutation failCampaignGoalSubmission(
    $input: FailCampaignGoalSubmissionInput!
  ) {
    failCampaignGoalSubmission(input: $input) {
      id
      status
      reason
    }
  }
`);

export const creatorHasCampaignContentFileDocument = graphql(`
  query creatorHasCampaignContentFile($id: ID!) {
    creatorHasCampaignContentFile(id: $id) {
      uri
      hash
    }
  }
`);

export const extendCampaignDeadlinesDocument = graphql(`
  mutation extendCampaignDeadlines($input: ExtendCampaignDeadlinesInput!) {
    extendCampaignDeadlines(input: $input) {
      id
      applyUntil
      submitUntil
    }
  }
`);

export const creatorHasCampaignMessageHeaderDocument = graphql(`
  query creatorHasCampaignMessageHeader($id: ID!) {
    creatorHasCampaign(id: $id) {
      id
      creator {
        id
        username
        profilePicture {
          hash
          uri
        }
      }
      campaign {
        id
        name
      }
    }
  }
`);
