import { useMutation } from '@apollo/client';
import {
  campaignsDocument,
  deleteCampaignDocument,
} from '@social-garden/api/documents/campaign.ts';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useDeleteCampaign() {
  const navigate = useNavigate();
  const [deleteCampaign, { loading, error }] = useMutation(
    deleteCampaignDocument,
    {
      refetchQueries: [campaignsDocument],
    },
  );

  const handleOnDelete = useCallback(
    async (id: string) => {
      const result = await deleteCampaign({
        variables: {
          id,
        },
      });
      if (result.data?.deleteCampaign) {
        navigate('/campaigns');
      }
    },
    [deleteCampaign, navigate],
  );

  return [handleOnDelete, { loading, error }] as const;
}
