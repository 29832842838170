import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { IGNORE_ERRORS } from '@social-garden/utils/ignoreErrors.ts';

export function initSentry() {
  return Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    enabled: import.meta.env.VITE_APP_ENVIRONMENT !== 'development',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        autoInject: false,
        colorScheme: 'system',
        showBranding: false,
        showName: false,
        showEmail: false,
      }),
    ],
    tracesSampleRate: 0.25,
    tracePropagationTargets: [import.meta.env.VITE_APP_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: IGNORE_ERRORS,
  });
}
