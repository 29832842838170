import { createParser } from 'nuqs';
import { z } from 'zod';

export const parseAsUUID = createParser({
  parse(queryValue) {
    const result = z.string().uuid().safeParse(queryValue);
    if (result.success) {
      return result.data;
    }
    return null;
  },
  serialize(value) {
    return value;
  },
});
