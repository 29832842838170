import { Container, Grid2 } from '@mui/material';
import { useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { managerDocument } from '@social-garden/api/documents/manager.ts';
import UpdatePassword from './UpdatePassword.tsx';
import RequestNewEmail from './RequestNewEmail.tsx';
import DeleteAccount from './DeleteAccount.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import GeneralSettings from './GeneralSettings.tsx';

export default function AccountSettings() {
  const { t } = useTranslation(['manager']);
  const { data } = useSuspenseQuery(managerDocument);

  if (data.whoAmI.__typename !== 'Manager') {
    throw new Error('Authenticated user is not a manager!');
  }

  return (
    <Container>
      <Grid2 container spacing={4}>
        <Grid2 size={12}>
          <PageTitle>{t('manager:accountSettings.title')}</PageTitle>
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}>
          <GeneralSettings />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}>
          <RequestNewEmail currentEmail={data.whoAmI.email} />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}>
          <UpdatePassword />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}>
          <DeleteAccount
            isLastManager={data.whoAmI.company.managers.length === 1}
          />
        </Grid2>
      </Grid2>
    </Container>
  );
}
