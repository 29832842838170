import { CreatorHasCampaignStatus } from '@social-garden/utils/types.ts';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuarterStroke } from '@fortawesome/pro-regular-svg-icons/faCircleQuarterStroke';
import { BLUE, GREEN, ORANGE } from '@social-garden/utils/colors.ts';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';

interface CampaignReportDataStatusProps {
  creatorHasCampaignStatus: CreatorHasCampaignStatus;
}

export default function CampaignReportDataStatus({
  creatorHasCampaignStatus,
}: CampaignReportDataStatusProps) {
  const { t } = useTranslation(['common']);

  switch (creatorHasCampaignStatus) {
    case CreatorHasCampaignStatus.APPLIED:
    case CreatorHasCampaignStatus.DECLINED:
    case CreatorHasCampaignStatus.SUBMISSION_PENDING:
    case CreatorHasCampaignStatus.SUBMITTED:
    case CreatorHasCampaignStatus.FAILED:
    case CreatorHasCampaignStatus.CANCELLED:
    case CreatorHasCampaignStatus.SUBMISSION_ACCEPTED:
      return (
        <Tooltip title={t('common:report.dataStatus.fetching')}>
          <FontAwesomeIcon
            icon={faCircleQuarterStroke}
            size="lg"
            color={BLUE['500']}
          />
        </Tooltip>
      );
    case CreatorHasCampaignStatus.REPORTING_COMPLETED:
      return (
        <Tooltip title={t('common:report.dataStatus.completed')}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="lg"
            color={GREEN['500']}
          />
        </Tooltip>
      );
    case CreatorHasCampaignStatus.REPORTING_FAILED:
      return (
        <Tooltip title={t('common:report.dataStatus.failed')}>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            size="lg"
            color={ORANGE['500']}
          />
        </Tooltip>
      );
    default:
      assertUnreachable(creatorHasCampaignStatus);
  }
}
