import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Suspense } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function RootLayoutSuspenseFallback() {
  const { t } = useTranslation(['manager']);
  return (
    <Stack
      sx={{
        background: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.primary.main
            : theme.palette.primary.dark,
        color: (theme) => theme.palette.primary.contrastText,
      }}
      flex={1}
      p={4}
      alignItems="center"
      justifyContent="center"
      textAlign="center">
      <Typography variant="h2" align="center">
        {t('root.suspenseFallback.appLoading')}
      </Typography>
      <Stack
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        p={4}>
        <CircularProgress color="inherit" />
      </Stack>
    </Stack>
  );
}

export default function RootLayout() {
  return (
    <>
      <Suspense fallback={<RootLayoutSuspenseFallback />}>
        <Outlet />
      </Suspense>
      <ScrollRestoration />
    </>
  );
}
