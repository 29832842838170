import { useCallback } from 'react';
import useInfiniteScroll from 'react-easy-infinite-scroll-hook';

export interface Paginator<Data> {
  data: Data[];
  paginatorInfo: {
    currentPage: number;
    hasMorePages: boolean;
  };
}

interface InfiniteScrollPaginationOptions<T> {
  paginator: Paginator<T>;
  fetchMore(options: { variables: { page: number } }): Promise<any>;
  reverse?: boolean;
}

export default function useInfiniteScrollPagination<Data>({
  paginator,
  fetchMore,
  reverse = false,
}: InfiniteScrollPaginationOptions<Data>) {
  const next = useCallback(async () => {
    if (paginator.paginatorInfo.hasMorePages) {
      await fetchMore({
        variables: {
          page: paginator.paginatorInfo.currentPage + 1,
        },
      });
    }
  }, [
    fetchMore,
    paginator.paginatorInfo.currentPage,
    paginator.paginatorInfo.hasMorePages,
  ]);

  useInfiniteScroll({
    next,
    rowCount: paginator.data.length,
    hasMore: {
      down: paginator.paginatorInfo.hasMorePages,
      up: false,
      left: false,
      right: false,
    },
    reverse: {
      column: reverse,
      row: false,
    },
    windowScroll: true,
  });

  return { next } as const;
}
