import { z } from 'zod';
import {
  IMAGE_SIZE_LIMIT_IN_BYTES,
  IMAGE_TYPES,
} from '@social-garden/utils/files';
import { URL_REGEX } from '@social-garden/utils/regex.ts';
import { formatBytes } from '@social-garden/utils/helpers';
import {
  ChannelType,
  InstagramContentType,
  TaskItemType,
  TiktokContentType,
  YoutubeContentType,
} from '@social-garden/utils/types';

export const EmailRule = z.string().trim().min(1).max(320).email();
export const PasswordRule = z.string().min(8).max(128);
export const CheckboxRule = z.literal<boolean>(true);
export const CodeRule = z.string().trim().min(8).max(8);
export const CompanyNameRule = z.string().trim().min(1).max(30);
export const BrandNameRule = z.string().trim().min(1).max(30);
export const MoneyRule = z.number().int().nonnegative().lte(99999999);
const UrlRule = z
  .string()
  .trim()
  .max(500)
  .url()
  .refine((value) => URL_REGEX.test(value), {
    params: {
      i18n: {
        key: 'errors.invalid_string.url',
        values: {
          validation: 'url',
        },
      },
    },
  });
export const CampaignNameRule = z.string().trim().min(1).max(30);
export const CampaignDescriptionRule = z.string().trim().min(1).max(1000);
export const CampaignAddressRule = z.string().trim().max(100).optional();
export const GiftNameRule = z.string().trim().min(1).max(30);
export const GiftDescriptionRule = z.string().trim().min(1).max(1000);
export const GiftCodeRule = z.string().trim().min(1).max(50);
export const ReasonRule = z.string().trim().min(1).max(1000);
export const ImageFileRule = z
  .custom<File>()
  .superRefine((file: File | undefined, ctx) => {
    if (file === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: {
          i18n: 'errors.invalid_type_received_undefined',
        },
      });
      return false;
    }

    if (!IMAGE_TYPES.find((imageType) => imageType === file.type)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: {
          i18n: {
            key: 'errors.invalid_type',
            values: { expected: IMAGE_TYPES.join(','), received: file.type },
          },
        },
      });
      return false;
    }

    if (file.size > IMAGE_SIZE_LIMIT_IN_BYTES) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        params: {
          i18n: {
            key: 'errors.invalid_type',
            values: {
              expected: formatBytes(IMAGE_SIZE_LIMIT_IN_BYTES, 'en'),
              received: formatBytes(file.size, 'en'),
            },
          },
        },
      });
      return false;
    }

    return true;
  });
export const QuestionRule = z.string().trim().min(1).max(150);
const HashtagRule = z
  .string()
  .trim()
  .min(1)
  .max(100)
  .startsWith('#')
  .regex(/^#[\p{L}0-9_]+$/u);
const WebsiteTaskItemRule = z.object({
  type: z.literal(TaskItemType.WEBSITE),
  websiteTask: z.object({
    url: UrlRule,
  }),
});
const HashtagTaskItemRule = z.object({
  type: z.literal(TaskItemType.HASHTAG),
  hashtagTask: z.object({
    hashtags: HashtagRule.array()
      .min(1)
      .max(10)
      .refine(
        (tags) =>
          tags.length === new Set(tags.map((tag) => tag.toLowerCase())).size,
        {
          params: {
            i18n: {
              key: 'errors.custom',
            },
          },
        },
      ),
  }),
});
const FreeTextTaskItemRule = z.object({
  type: z.literal(TaskItemType.FREE_TEXT),
  freeTextTask: z.object({
    description: z.string().trim().min(1).max(200),
  }),
});
export const TaskItemRule = z.union([
  WebsiteTaskItemRule,
  HashtagTaskItemRule,
  FreeTextTaskItemRule,
]);
export const TextMessageRule = z.string().trim().min(1).max(500);
export const CampaignTypeRule = z.object({
  channelType: z.nativeEnum(ChannelType),
  instagramCampaign: z
    .object({
      contentType: z.nativeEnum(InstagramContentType),
    })
    .optional(),
  youtubeCampaign: z
    .object({
      contentType: z.nativeEnum(YoutubeContentType),
    })
    .optional(),
  tiktokCampaign: z
    .object({
      contentType: z.nativeEnum(TiktokContentType),
    })
    .optional(),
});
export const IDRule = z.string().uuid();
export const BooleanRule = z.boolean().default(false);
