import { MouseEventHandler, useMemo } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemoPad } from '@fortawesome/pro-regular-svg-icons/faMemoPad';
import { faChartMixed } from '@fortawesome/pro-regular-svg-icons/faChartMixed';
import { faGaugeHigh } from '@fortawesome/pro-regular-svg-icons/faGaugeHigh';
import { faTags } from '@fortawesome/pro-regular-svg-icons/faTags';
import { faObjectsColumn } from '@fortawesome/pro-regular-svg-icons/faObjectsColumn';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface SideNavigationProps {
  onListItemClick?: MouseEventHandler;
}

export default function SideNavigation({
  onListItemClick,
}: SideNavigationProps) {
  const { t } = useTranslation(['manager']);

  const topNavigationItems = useMemo(() => {
    return [
      {
        label: t('navigation.dashboard'),
        icon: faObjectsColumn,
        path: '/',
      },
      {
        label: t('navigation.campaigns'),
        icon: faMemoPad,
        path: '/campaigns',
      },
      {
        label: t('navigation.reports'),
        icon: faChartMixed,
        path: '/campaign-reports',
      },
      {
        label: t('navigation.brands'),
        icon: faTags,
        path: '/brands',
      },
    ];
  }, [t]);

  const bottomNavigationItems = useMemo(() => {
    return [
      {
        label: t('navigation.subscription'),
        icon: faGaugeHigh,
        path: '/subscription',
      },
    ];
  }, [t]);

  return (
    <Stack flex={1}>
      <List sx={{ flex: 1 }}>
        {topNavigationItems.map(({ label, icon, path }) => (
          <ListItem sx={{ px: 0 }} key={path}>
            <ListItemButton
              component={Link}
              to={path}
              color="inherit"
              onClick={onListItemClick}>
              <ListItemIcon
                sx={{
                  color: 'inherit',
                }}>
                <FontAwesomeIcon icon={icon} size="lg" />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List>
        {bottomNavigationItems.map(({ label, icon, path }) => (
          <ListItem sx={{ px: 0 }} key={path}>
            <ListItemButton
              component={Link}
              to={path}
              color="inherit"
              onClick={onListItemClick}>
              <ListItemIcon
                sx={{
                  color: 'inherit',
                }}>
                <FontAwesomeIcon icon={icon} size="lg" />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
