import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InstagramChannelInsights, {
  InstagramChannelInsightsData,
} from './InstagramChannelInsights.tsx';

interface InstagramChannelInsightsDialogProps {
  open: boolean;
  data: InstagramChannelInsightsData;
  onClose: () => void;
}

export default function InstagramChannelInsightsDialog({
  open,
  data,
  onClose,
}: InstagramChannelInsightsDialogProps) {
  const { t } = useTranslation(['common', 'manager']);

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>{t('manager:channelInsights.title')}</DialogTitle>
      <DialogContent>
        <InstagramChannelInsights data={data} />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          {t('common:done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
