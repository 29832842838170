import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import RootRoutes from './RootRoutes.tsx';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([RootRoutes]);

export default router;
