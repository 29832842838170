import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardProps,
  Chip,
  Grid2,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  getContrastColor,
  getUriWithHash,
} from '@social-garden/utils/helpers.ts';
import {
  CampaignStatus,
  CampaignTypeable,
  Download,
} from '@social-garden/utils/types.ts';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  getChannelTypeColor,
  getChannelTypeName,
} from '@social-garden/utils/channel.ts';
import { getContentTypeNameFromCampaignType } from '@social-garden/utils/content.ts';
import { useCallback, useMemo } from 'react';
import Brand from './Brand.tsx';
import useCampaignStatus from '../hooks/useCampaignStatus.ts';

export interface CampaignListItemData {
  id: string;
  name: string;
  status: CampaignStatus;
  headerImage: Download;
  brand: {
    name: string;
    logo: Download;
  };
  typeable: CampaignTypeable;
  statistics?: {
    reviewApplicationBefore?: string | null;
    reviewSubmissionBefore?: string | null;
  } | null;
}

interface CampaignListItemProps extends Omit<CardProps, 'children'> {
  data: CampaignListItemData;
}

export default function CampaignListItem({
  data,
  ...rest
}: CampaignListItemProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['manager']);

  const { getCampaignStatusName } = useCampaignStatus();

  const getReviewBeforeTooltipTitle = useCallback(
    (date: number | string | Date) =>
      t('manager:campaign.reviewBefore', {
        date: new Date(date).toLocaleString(resolvedLanguage, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
      }),
    [t, resolvedLanguage],
  );

  const mediaFilter = useMemo(() => {
    if (
      data.statistics?.reviewApplicationBefore ||
      data.statistics?.reviewSubmissionBefore
    ) {
      return 'grayscale(85%) brightness(85%)';
    }
    return 'none';
  }, [
    data.statistics?.reviewApplicationBefore,
    data.statistics?.reviewSubmissionBefore,
  ]);

  return (
    <Card {...rest}>
      <CardActionArea
        sx={{
          position: 'relative',
        }}
        component={Link}
        to={`/campaign-details/${data.id}`}>
        <CardMedia
          sx={{
            aspectRatio: 21 / 9,
            filter: mediaFilter,
          }}
          image={getUriWithHash(data.headerImage)}
          title={data.name}
          draggable={false}
        />
        <Stack position="absolute" top={5} right={5} spacing={1}>
          {data.statistics?.reviewApplicationBefore ? (
            <Tooltip
              title={getReviewBeforeTooltipTitle(
                data.statistics.reviewApplicationBefore,
              )}
              disableInteractive>
              <Chip
                label={t('manager:campaign.unreviewedApplications')}
                size="small"
                color="applied"
              />
            </Tooltip>
          ) : null}
          {data.statistics?.reviewSubmissionBefore ? (
            <Tooltip
              title={getReviewBeforeTooltipTitle(
                data.statistics.reviewSubmissionBefore,
              )}
              disableInteractive>
              <Chip
                label={t('manager:campaign.unreviewedSubmissions')}
                size="small"
                color="submitted"
              />
            </Tooltip>
          ) : null}
        </Stack>
        <CardContent
          component={Stack}
          spacing={1}
          alignItems="stretch"
          justifyContent="center">
          <Typography
            variant="subtitle1"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            lineHeight={1.2}
            fontSize={20}>
            {data.name}
          </Typography>
          <Brand name={data.brand.name} logo={data.brand.logo} size="small" />
          <Grid2 container spacing={1}>
            <Grid2 size="auto">
              <Chip
                label={getCampaignStatusName(data.status)}
                variant="filled"
                size="small"
              />
            </Grid2>
            <Grid2 size="auto">
              <Chip
                sx={{
                  backgroundColor: getChannelTypeColor(
                    data.typeable.channelType,
                  ),
                  color: getContrastColor(
                    getChannelTypeColor(data.typeable.channelType),
                  ),
                }}
                label={getChannelTypeName(data.typeable.channelType)}
                variant="filled"
                size="small"
              />
            </Grid2>
            <Grid2 size="auto">
              <Chip
                sx={{
                  backgroundColor: getChannelTypeColor(
                    data.typeable.channelType,
                  ),
                  color: getContrastColor(
                    getChannelTypeColor(data.typeable.channelType),
                  ),
                }}
                label={getContentTypeNameFromCampaignType(data.typeable)}
                variant="filled"
                size="small"
              />
            </Grid2>
          </Grid2>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
