import * as Sentry from '@sentry/react';
import { RouterProvider } from 'react-router-dom';
import { NuqsAdapter } from 'nuqs/adapters/react-router';
import { CssBaseline, Stack } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DragEventHandler, useCallback } from 'react';
import router from './routing/router.ts';
import SuspenseFallback from './components/SuspenseFallback.tsx';
import useDateFnsLocale from './hooks/useDateFnsLocale.ts';
import ColorModeProvider from './providers/ColorModeProvider.tsx';
import ThemeProvider from './providers/ThemeProvider.tsx';
import ApolloClientProvider from './providers/ApolloClientProvider.tsx';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback.tsx';

export default function App() {
  const adapterLocale = useDateFnsLocale();

  const handleDragEvent = useCallback<DragEventHandler<HTMLDivElement>>(
    (event) => event.preventDefault(),
    [],
  );

  return (
    <ColorModeProvider storageKey="manager-social-garden-color-mode">
      <ThemeProvider>
        <CssBaseline />
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={adapterLocale}>
          <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
            <SnackbarProvider
              maxSnack={5}
              autoHideDuration={5000}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <ApolloClientProvider>
                <Stack
                  minHeight="100vh"
                  onDragOver={handleDragEvent}
                  onDrop={handleDragEvent}>
                  <NuqsAdapter>
                    <RouterProvider
                      router={router}
                      fallbackElement={<SuspenseFallback />}
                    />
                  </NuqsAdapter>
                </Stack>
              </ApolloClientProvider>
            </SnackbarProvider>
          </Sentry.ErrorBoundary>
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeProvider>
  );
}
