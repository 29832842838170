import { Grid2, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  abbreviateCount,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import ChannelStatisticItem from './ChannelStatisticItem.tsx';
import ChannelName from './ChannelName.tsx';

export interface TiktokChannelStatisticsData {
  displayName: string;
  url: string;
  followerCount: number;
  videoCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
}

interface TiktokChannelStatisticsProps {
  data: TiktokChannelStatisticsData;
}

export default function TiktokChannelStatistics({
  data,
}: TiktokChannelStatisticsProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  return (
    <Stack spacing={1}>
      <Stack direction="row">
        <ChannelName name={data.displayName} url={data.url} />
      </Stack>
      <Grid2 container spacing={2}>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.followerCount', {
              count: data.followerCount,
            })}
            value={abbreviateCount(data.followerCount, resolvedLanguage)}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageEngagementRate')}
            value={formatPercentage(
              data.averageEngagementRate || 0,
              resolvedLanguage,
            )}
            description={t('common:channel.averageEngagementRateDescription')}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageReach')}
            value={abbreviateCount(data.averageReach || 0, resolvedLanguage)}
            description={t('common:channel.averageReachDescription')}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.videoCount', {
              count: data.videoCount,
            })}
            value={abbreviateCount(data.videoCount, resolvedLanguage)}
            description={t('common:channel.videoCountDescription')}
          />
        </Grid2>
      </Grid2>
    </Stack>
  );
}
