import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
} from '@mui/material';
import { CampaignStatus } from '@social-garden/utils/types.ts';
import { isPast, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { faMemoPad } from '@fortawesome/pro-regular-svg-icons/faMemoPad';
import { faPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { faClone } from '@fortawesome/pro-regular-svg-icons/faClone';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faShare } from '@fortawesome/pro-regular-svg-icons/faShare';
import { completeCampaignDocument } from '@social-garden/api/documents/campaign.ts';
import { faForwardStep } from '@fortawesome/pro-regular-svg-icons/faForwardStep';
import { faTimelineArrow } from '@fortawesome/pro-regular-svg-icons/faTimelineArrow';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons/faBullhorn';
import useCampaignUsage from '../../hooks/useCampaignUsage.ts';
import useStorageSpaceUsage from '../../hooks/useStorageSpaceUsage.ts';
import useDuplicateCampaign from '../../hooks/useDuplicateCampaign.ts';
import usePublishCampaign from '../../hooks/usePublishCampaign.ts';
import SuspenseFallback from '../../components/SuspenseFallback.tsx';
import CampaignBriefing from './CampaignBriefing.tsx';
import HeaderActionButton from '../../components/HeaderActionButton.tsx';
import useDeleteCampaign from '../../hooks/useDeleteCampaign.ts';
import useCopyToClipboard from '../../hooks/useCopyToClipboard.ts';
import ExtendCampaignDeadlinesDialog from './ExtendCampaignDeadlinesDialog.tsx';

export interface CampaignDetailsHeaderActionButtonProps {
  campaign: {
    id: string;
    applyUntil: string;
    submitUntil: string;
    includeContentFileOnSubmission: boolean;
    status: CampaignStatus;
  };
}

export default function CampaignDetailsHeaderActionButton({
  campaign,
}: CampaignDetailsHeaderActionButtonProps) {
  const { t } = useTranslation(['common', 'manager']);
  const [briefingDialogOpen, setBriefingDialogOpen] = useState<boolean>(false);
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const [
    extendCampaignDeadlinesDialogOpen,
    setExtendCampaignDeadlinesDialogOpen,
  ] = useState<boolean>(false);
  const [copyToClipboard] = useCopyToClipboard();
  const navigate = useNavigate();
  const { exceeded: campaignUsageExceeded } = useCampaignUsage();
  const { exceeded: storageSpaceUsageExceeded } = useStorageSpaceUsage();

  const shareUrl = useMemo(
    () =>
      `${import.meta.env.VITE_CREATOR_APP_URL}/campaign-details/${campaign.id}`,
    [campaign.id],
  );

  const [duplicateCampaign, { loading: duplicateCampaignLoading }] =
    useDuplicateCampaign();

  const [publishCampaign, { loading: publishCampaignLoading }] =
    usePublishCampaign();

  const [deleteCampaign, { loading: deleteCampaignLoading }] =
    useDeleteCampaign();

  const [completeCampaign, { loading: completeCampaignLoading }] = useMutation(
    completeCampaignDocument,
  );

  const handleOnOpenBriefingDialog = useCallback(
    () => setBriefingDialogOpen(true),
    [],
  );

  const handleOnCloseBriefingDialog = useCallback(
    () => setBriefingDialogOpen(false),
    [],
  );

  const handleOnOpenShareDialog = useCallback(() => {
    setShareDialogOpen(true);
  }, []);

  const handleOnCloseShareDialog = useCallback(() => {
    setShareDialogOpen(false);
  }, []);

  const handleOnOpenExtendCampaignDeadlinesDialog = useCallback(() => {
    setExtendCampaignDeadlinesDialogOpen(true);
  }, []);

  const handleOnCloseExtendCampaignDeadlinesDialog = useCallback(() => {
    setExtendCampaignDeadlinesDialogOpen(false);
  }, []);

  const handleCopyToClipboard = useCallback(async () => {
    await copyToClipboard(shareUrl);
  }, [shareUrl, copyToClipboard]);

  const handleOnDuplicate = useCallback(async () => {
    await duplicateCampaign(campaign.id);
  }, [campaign.id, duplicateCampaign]);

  const handleOnEdit = useCallback(async () => {
    if (campaign.status === CampaignStatus.DRAFT) {
      navigate(`/update-campaign/${campaign.id}`);
    }
  }, [campaign.status, campaign.id, navigate]);

  const handleOnPublish = useCallback(async () => {
    if (campaign.status === CampaignStatus.DRAFT) {
      await publishCampaign(campaign.id);
    }
  }, [campaign.id, campaign.status, publishCampaign]);

  const handleOnDelete = useCallback(async () => {
    if (campaign.status === CampaignStatus.DRAFT) {
      await deleteCampaign(campaign.id);
    }
  }, [campaign.id, campaign.status, deleteCampaign]);

  const handleOnComplete = useCallback(async () => {
    if (campaign.status === CampaignStatus.ACTIVE) {
      await completeCampaign({
        variables: {
          id: campaign.id,
        },
      });
    }
  }, [campaign.id, campaign.status, completeCampaign]);

  return (
    <>
      <HeaderActionButton
        actions={[
          {
            primary: campaign.status === CampaignStatus.DRAFT,
            icon: faBullhorn,
            label: t('common:publish'),
            hidden: campaign.status !== CampaignStatus.DRAFT,
            disabled:
              campaignUsageExceeded ||
              (campaign.includeContentFileOnSubmission &&
                storageSpaceUsageExceeded) ||
              isPast(new Date(campaign.applyUntil)) ||
              isToday(new Date(campaign.applyUntil).setUTCHours(0, 0, 0, 0)) ||
              publishCampaignLoading,
            confirm: {
              title: t('manager:campaign.confirmPublish.title'),
              description: t('manager:campaign.confirmPublish.description'),
            },
            onClick: handleOnPublish,
          },
          {
            primary: campaign.status !== CampaignStatus.DRAFT,
            icon: faMemoPad,
            label: t('manager:campaign.briefing'),
            onClick: handleOnOpenBriefingDialog,
          },
          {
            icon: faClone,
            label: t('common:duplicate'),
            disabled: duplicateCampaignLoading,
            onClick: handleOnDuplicate,
          },
          {
            icon: faPen,
            label: t('common:edit'),
            hidden: campaign.status !== CampaignStatus.DRAFT,
            onClick: handleOnEdit,
          },
          {
            icon: faTrash,
            label: t('common:delete'),
            hidden: campaign.status !== CampaignStatus.DRAFT,
            disabled: deleteCampaignLoading,
            confirm: {
              title: t('manager:campaign.confirmDelete.title'),
              description: t('manager:campaign.confirmDelete.description'),
              buttonText: t('common:delete'),
              color: 'error',
            },
            onClick: handleOnDelete,
          },
          {
            icon: faShare,
            label: t('common:share'),
            hidden: campaign.status !== CampaignStatus.ACTIVE,
            onClick: handleOnOpenShareDialog,
          },
          {
            icon: faForwardStep,
            label: t('common:complete'),
            hidden: campaign.status !== CampaignStatus.ACTIVE,
            disabled: completeCampaignLoading,
            confirm: {
              title: t('manager:campaign.confirmComplete.title'),
              description: t('manager:campaign.confirmComplete.description'),
              buttonText: t('common:complete'),
              color: 'warning',
            },
            onClick: handleOnComplete,
          },
          {
            icon: faTimelineArrow,
            label: t('manager:campaign.extendDeadlines.menuItem'),
            hidden: campaign.status !== CampaignStatus.ACTIVE,
            onClick: handleOnOpenExtendCampaignDeadlinesDialog,
          },
        ]}
        variant="contained"
        size="large"
        color="secondary"
      />
      <Dialog
        open={briefingDialogOpen}
        maxWidth="sm"
        fullWidth
        scroll="body"
        onClose={handleOnCloseBriefingDialog}>
        <Suspense fallback={<SuspenseFallback />}>
          <CampaignBriefing
            sx={{ border: 0 }}
            campaignId={campaign.id}
            variant="outlined"
            elevation={0}>
            <Grid2 size="auto" offset="auto">
              <Button color="inherit" onClick={handleOnCloseBriefingDialog}>
                {t('common:done')}
              </Button>
            </Grid2>
          </CampaignBriefing>
        </Suspense>
      </Dialog>
      <Dialog open={shareDialogOpen} onClose={handleOnCloseShareDialog}>
        <DialogTitle>{t('common:share')}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              p: 1,
              border: 1,
              borderColor: 'divider',
              borderRadius: 1,
            }}>
            <code
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              }}>
              {shareUrl}
            </code>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleOnCloseShareDialog}>
            {t('common:close')}
          </Button>
          <Button variant="contained" onClick={handleCopyToClipboard}>
            {t('common:copy')}
          </Button>
        </DialogActions>
      </Dialog>
      <ExtendCampaignDeadlinesDialog
        open={extendCampaignDeadlinesDialogOpen}
        campaign={campaign}
        onClose={handleOnCloseExtendCampaignDeadlinesDialog}
      />
    </>
  );
}
