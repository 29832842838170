import { faMessage } from '@fortawesome/pro-thin-svg-icons/faMessage';
import { useQuery } from '@apollo/client';
import { messagesDocument } from '@social-garden/api/documents/message.ts';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import SuspenseFallback from '../../components/SuspenseFallback.tsx';
import StackList from '../../components/StackList.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import MessageListItem, {
  MessageListItemData,
} from '../../components/MessageListItem.tsx';

interface MessagesListProps {
  creatorHasCampaignId: string;
}

export default function MessagesList({
  creatorHasCampaignId,
}: MessagesListProps) {
  const { t } = useTranslation(['manager']);

  const { data, loading, fetchMore } = useQuery(messagesDocument, {
    variables: {
      creatorHasCampaignId,
      first: 25,
      page: 1,
    },
    pollInterval: 60000,
  });

  const renderItem = useCallback(
    (messageData: MessageListItemData) => (
      <MessageListItem key={messageData.id} data={messageData} />
    ),
    [],
  );

  if (data === undefined) {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center">
        <SuspenseFallback />
      </Stack>
    );
  }

  return (
    <StackList<MessageListItemData>
      sx={{
        flex: 1,
        overflowY: 'scroll',
        px: 2,
        py: 2,
      }}
      paginator={data.messages}
      fetchMore={fetchMore}
      renderItem={renderItem}
      loading={loading}
      reverse
      spacing={2}
      ListEmptyComponent={
        <Stack flex={1} justifyContent="center" alignItems="center">
          <EmptyState
            icon={faMessage}
            text={t('manager:messages.messageList.empty')}
          />
        </Stack>
      }
    />
  );
}
