import * as Sentry from '@sentry/react';
import { useCallback, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { persistor } from '../utils/apolloInMemoryCache.ts';

export default function RouteError() {
  const { t } = useTranslation(['manager']);
  const error = useRouteError();
  const client = useApolloClient();

  const handleOnClick = useCallback(async () => {
    await persistor.purge();
    await client.clearStore();
    window.location.replace('/');
  }, [client]);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Stack
      flex={1}
      spacing={3}
      p={3}
      alignItems="center"
      justifyContent="center"
      textAlign="center">
      <Typography variant="h2">{t('errorBoundary.header')}</Typography>
      <Typography variant="h4">{t('errorBoundary.description')}</Typography>
      <Button variant="text" onClick={handleOnClick}>
        {t('errorBoundary.actionButton.restart')}
      </Button>
    </Stack>
  );
}
