import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  Button,
  Chip,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { registerCompanyDocument } from '@social-garden/api/documents/company.ts';
import {
  RegisterCompanyFieldValues,
  RegisterCompanySchema,
} from '../../constants/ValidationSchema.ts';
import CompanyNameField from '../../components/CompanyNameField.tsx';
import EmailField from '../../components/EmailField.tsx';
import ConfirmPasswordField from '../../components/ConfirmPasswordField.tsx';
import NewPasswordField from '../../components/NewPasswordField.tsx';
import TermsOfServiceField from '../../components/TermsOfServiceField.tsx';
import PrivacyPolicyField from '../../components/PrivacyPolicyField.tsx';
import NewsletterField from '../../components/NewsletterField.tsx';

export default function Register() {
  const {
    t,
    i18n: { resolvedLanguage, languages },
  } = useTranslation(['manager']);
  const navigate = useNavigate();

  const [registerCompany, { loading }] = useMutation(registerCompanyDocument);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<RegisterCompanyFieldValues>({
    resolver: zodResolver(RegisterCompanySchema),
    mode: 'all',
  });

  const handleOnValid = useCallback(
    async ({
      name,
      email,
      password,
      confirmedTermsOfService,
      confirmedPrivacyTerms,
      acceptedNewsletter,
    }: RegisterCompanyFieldValues) => {
      const result = await registerCompany({
        variables: {
          input: {
            name,
            manager: {
              email,
              password,
              confirmedTermsOfService,
              confirmedPrivacyTerms,
              acceptedNewsletter,
              locale: resolvedLanguage ?? languages[0],
            },
          },
        },
      });
      if (result.data?.registerCompany) {
        navigate(`/verify-email?email=${encodeURIComponent(email)}`, {
          state: {
            password,
          },
        });
      }
    },
    [registerCompany, navigate, resolvedLanguage, languages],
  );

  return (
    <Stack spacing={4}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h5" align="center">
          {t('register.header')}
        </Typography>
      </Stack>
      <Stack
        component="form"
        spacing={2}
        noValidate
        onSubmit={handleSubmit(handleOnValid)}>
        <Divider>
          <Chip label={t('register.companyHeader')} variant="outlined" />
        </Divider>
        <Controller<RegisterCompanyFieldValues, 'name'>
          name="name"
          control={control}
          render={CompanyNameField}
        />
        <Divider>
          <Chip label={t('register.managerHeader')} variant="outlined" />
        </Divider>
        <Controller<RegisterCompanyFieldValues, 'email'>
          name="email"
          control={control}
          render={EmailField}
        />
        <Controller<RegisterCompanyFieldValues, 'password'>
          name="password"
          control={control}
          render={NewPasswordField}
        />
        <Controller<RegisterCompanyFieldValues, 'passwordConfirm'>
          name="passwordConfirm"
          control={control}
          render={ConfirmPasswordField}
        />
        <Controller<RegisterCompanyFieldValues, 'confirmedPrivacyTerms'>
          name="confirmedPrivacyTerms"
          control={control}
          render={PrivacyPolicyField}
        />
        <Controller<RegisterCompanyFieldValues, 'confirmedTermsOfService'>
          name="confirmedTermsOfService"
          control={control}
          render={TermsOfServiceField}
        />
        <Controller<RegisterCompanyFieldValues, 'acceptedNewsletter'>
          name="acceptedNewsletter"
          control={control}
          render={NewsletterField}
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={!isValid || loading}>
          {t('register.submit')}
        </Button>
        <MuiLink component={Link} to="/login">
          {t('register.loginLink')}
        </MuiLink>
      </Stack>
    </Stack>
  );
}
