import { Box, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import { ReactNode } from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface InfoIconProps {
  title: ReactNode;
  color?: string;
  size?: SizeProp;
}

export default function InfoIcon({
  title,
  color = 'info.main',
  size,
}: InfoIconProps) {
  return (
    <Tooltip title={title}>
      <Box color={color}>
        <FontAwesomeIcon icon={faCircleInfo} size={size} />
      </Box>
    </Tooltip>
  );
}
