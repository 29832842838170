import { Button, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function ErrorBoundaryFallback() {
  const { t } = useTranslation(['manager']);

  const handleOnClick = useCallback(() => {
    localStorage.clear();
    window.location.replace('/');
  }, []);

  return (
    <Stack
      minHeight="100vh"
      flex={1}
      spacing={3}
      p={3}
      alignItems="center"
      justifyContent="center"
      textAlign="center">
      <Typography variant="h2">{t('errorBoundary.header')}</Typography>
      <Typography variant="h4">{t('errorBoundary.description')}</Typography>
      <Button variant="text" onClick={handleOnClick}>
        {t('errorBoundary.actionButton.restart')}
      </Button>
    </Stack>
  );
}
