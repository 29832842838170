import { Typography, TypographyProps } from '@mui/material';
import { ReactNode, useEffect } from 'react';

interface PageTitleProps extends TypographyProps {
  variant?: 'h1' | 'h2' | 'h3';
  children: ReactNode;
}

export default function PageTitle({
  sx,
  variant = 'h2',
  children,
  ...rest
}: PageTitleProps) {
  useEffect(() => {
    let title = import.meta.env.VITE_APP_NAME;
    if (import.meta.env.VITE_APP_ENVIRONMENT !== 'production') {
      title = `${import.meta.env.VITE_APP_NAME} (${import.meta.env.VITE_APP_ENVIRONMENT})`;
    }

    if (typeof children === 'string') {
      document.title = `${children} - ${title}`;
    } else {
      document.title = title;
    }
    return () => {
      document.title = title;
    };
  }, [children]);

  return (
    <Typography
      sx={{
        wordWrap: 'break-word',
        ...sx,
      }}
      variant={variant}
      {...rest}>
      {children}
    </Typography>
  );
}
