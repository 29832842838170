import { MouseEventHandler, useCallback } from 'react';
import { Link } from '@mui/material';

interface ChannelNameProps {
  name: string;
  url: string;
}

export default function ChannelName({ name, url }: ChannelNameProps) {
  const handleOnUrlClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      window.open(url, '_blank');
    },
    [url],
  );

  return (
    <Link component="button" onClick={handleOnUrlClick}>
      {name}
    </Link>
  );
}
