import { useTranslation } from 'react-i18next';
import {
  Alert,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { CreatorHasCampaignStatus } from '@social-garden/utils/types.ts';
import ReviewCampaignApplication, {
  ReviewCampaignApplicationProps,
} from './ReviewCampaignApplication.tsx';
import ReviewCampaignGoalSubmission, {
  ReviewCampaignGoalSubmissionProps,
} from './ReviewCampaignGoalSubmission.tsx';
import CampaignApplication, {
  CampaignApplicationProps,
} from './CampaignApplication.tsx';
import CampaignGoalSubmission, {
  CampaignGoalSubmissionProps,
} from './CampaignGoalSubmission.tsx';
import CampaignGoalSubmissionPending, {
  CampaignGoalSubmissionPendingProps,
} from './CampaignGoalSubmissionPending.tsx';
import CampaignGoalSubmissionFailed, {
  CampaignGoalSubmissionFailedProps,
} from './CampaignGoalSubmissionFailed.tsx';
import CampaignGoalSubmissionCompleted, {
  CampaignGoalSubmissionCompletedProps,
} from './CampaignGoalSubmissionCompleted.tsx';

const STEPS = [0, 1, 2, 3] as const;
type StepIndex = (typeof STEPS)[number];

function getActiveStepFromStatus(status: CreatorHasCampaignStatus): StepIndex {
  switch (status) {
    case CreatorHasCampaignStatus.APPLIED:
      return 0;
    case CreatorHasCampaignStatus.SUBMISSION_PENDING:
    case CreatorHasCampaignStatus.DECLINED:
    case CreatorHasCampaignStatus.CANCELLED:
      return 1;
    case CreatorHasCampaignStatus.SUBMITTED:
      return 2;
    case CreatorHasCampaignStatus.SUBMISSION_ACCEPTED:
    case CreatorHasCampaignStatus.FAILED:
    case CreatorHasCampaignStatus.REPORTING_COMPLETED:
    case CreatorHasCampaignStatus.REPORTING_FAILED:
      return 3;
    default:
      assertUnreachable(status);
  }
}

function isStepCompleted(
  stepIndex: StepIndex,
  status: CreatorHasCampaignStatus,
) {
  return (
    getActiveStepFromStatus(status) > stepIndex ||
    getActiveStepFromStatus(status) === STEPS[STEPS.length - 1]
  );
}

function isStepActive(stepIndex: StepIndex, status: CreatorHasCampaignStatus) {
  if (stepIndex === 1 && getActiveStepFromStatus(status) > 1) {
    return false;
  }
  return stepIndex <= getActiveStepFromStatus(status);
}

function isStepDisabled(
  stepIndex: StepIndex,
  status: CreatorHasCampaignStatus,
) {
  return (
    stepIndex > getActiveStepFromStatus(status) ||
    (stepIndex < getActiveStepFromStatus(status) && stepIndex === 1)
  );
}

type CreatorHasCampaignFlowProps = CampaignApplicationProps &
  ReviewCampaignApplicationProps &
  CampaignGoalSubmissionPendingProps &
  CampaignGoalSubmissionProps &
  ReviewCampaignGoalSubmissionProps &
  CampaignGoalSubmissionFailedProps &
  CampaignGoalSubmissionCompletedProps & {
    creatorHasCampaign: {
      status: CreatorHasCampaignStatus;
    };
  };

export default function CreatorHasCampaignFlow({
  creatorHasCampaign,
}: CreatorHasCampaignFlowProps) {
  const { t } = useTranslation(['common', 'manager']);

  return (
    <Stack maxWidth={800}>
      <Stepper
        activeStep={getActiveStepFromStatus(creatorHasCampaign.status)}
        orientation="vertical"
        nonLinear>
        <Step
          active={isStepActive(0, creatorHasCampaign.status)}
          completed={isStepCompleted(0, creatorHasCampaign.status)}
          disabled={isStepDisabled(0, creatorHasCampaign.status)}>
          <StepLabel>
            <Typography variant="h6">
              {t('manager:creatorHasCampaignFlow.reviewApplicationStep.label')}
            </Typography>
          </StepLabel>
          <StepContent>
            {isStepCompleted(0, creatorHasCampaign.status) ? (
              <CampaignApplication creatorHasCampaign={creatorHasCampaign} />
            ) : (
              <ReviewCampaignApplication
                creatorHasCampaign={creatorHasCampaign}
              />
            )}
          </StepContent>
        </Step>
        <Step
          active={isStepActive(1, creatorHasCampaign.status)}
          completed={isStepCompleted(1, creatorHasCampaign.status)}
          disabled={isStepDisabled(1, creatorHasCampaign.status)}>
          {creatorHasCampaign.status === CreatorHasCampaignStatus.DECLINED ? (
            <>
              <StepLabel error>
                <Typography variant="h6">
                  {t('common:creatorHasCampaignStatus.declined')}
                </Typography>
              </StepLabel>
              <StepContent>
                <Alert severity="info">
                  {t('manager:creatorHasCampaignFlow.declined.content')}
                </Alert>
              </StepContent>
            </>
          ) : null}
          {creatorHasCampaign.status === CreatorHasCampaignStatus.CANCELLED ? (
            <>
              <StepLabel error>
                <Typography variant="h6">
                  {t('common:creatorHasCampaignStatus.cancelled')}
                </Typography>
              </StepLabel>
              <StepContent>
                <Alert severity="info">
                  {t('manager:creatorHasCampaignFlow.cancelled.content')}
                </Alert>
              </StepContent>
            </>
          ) : null}
          {creatorHasCampaign.status !== CreatorHasCampaignStatus.DECLINED &&
          creatorHasCampaign.status !== CreatorHasCampaignStatus.CANCELLED ? (
            <>
              <StepLabel>
                <Typography variant="h6">
                  {t(
                    'manager:creatorHasCampaignFlow.waitForSubmissionStep.label',
                  )}
                </Typography>
              </StepLabel>
              <StepContent>
                <CampaignGoalSubmissionPending
                  creatorHasCampaign={creatorHasCampaign}
                />
              </StepContent>
            </>
          ) : null}
        </Step>
        <Step
          active={isStepActive(2, creatorHasCampaign.status)}
          completed={isStepCompleted(2, creatorHasCampaign.status)}
          disabled={isStepDisabled(2, creatorHasCampaign.status)}>
          <StepLabel>
            <Typography variant="h6">
              {t('manager:creatorHasCampaignFlow.reviewSubmissionStep.label')}
            </Typography>
          </StepLabel>
          <StepContent>
            {isStepCompleted(2, creatorHasCampaign.status) ? (
              <CampaignGoalSubmission creatorHasCampaign={creatorHasCampaign} />
            ) : (
              <ReviewCampaignGoalSubmission
                creatorHasCampaign={creatorHasCampaign}
              />
            )}
          </StepContent>
        </Step>
        <Step
          active={isStepActive(3, creatorHasCampaign.status)}
          completed={isStepCompleted(3, creatorHasCampaign.status)}
          disabled={isStepDisabled(3, creatorHasCampaign.status)}>
          {creatorHasCampaign.status === CreatorHasCampaignStatus.FAILED ? (
            <>
              <StepLabel error>
                <Typography variant="h6">
                  {t('common:creatorHasCampaignStatus.failed')}
                </Typography>
              </StepLabel>
              <StepContent>
                <CampaignGoalSubmissionFailed
                  creatorHasCampaign={creatorHasCampaign}
                />
              </StepContent>
            </>
          ) : (
            <>
              <StepLabel>
                <Typography variant="h6">
                  {t('common:creatorHasCampaignStatus.submissionAccepted')}
                </Typography>
              </StepLabel>
              <StepContent>
                <CampaignGoalSubmissionCompleted
                  creatorHasCampaign={creatorHasCampaign}
                />
              </StepContent>
            </>
          )}
        </Step>
      </Stepper>
    </Stack>
  );
}
