import { Button, Container, Stack } from '@mui/material';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  resendManagerVerificationEmailDocument,
  verifyAndUpdateManagerEmailDocument,
} from '@social-garden/api/documents/manager.ts';
import {
  VerifyEmailForm,
  VerifyEmailSchema,
} from '../../constants/ValidationSchema.ts';
import CodeField from '../../components/CodeField.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import ReadOnlyEmailField from '../../components/ReadOnlyEmailField.tsx';

export default function VerifyUpdatedEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['manager']);
  const [
    verifyAndUpdateManagerEmail,
    { loading: verifyAndUpdateManagerEmailLoading },
  ] = useMutation(verifyAndUpdateManagerEmailDocument);
  const [
    resendManagerVerificationEmail,
    { loading: resendManagerVerificationEmailLoading },
  ] = useMutation(resendManagerVerificationEmailDocument);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<VerifyEmailForm>({
    resolver: zodResolver(VerifyEmailSchema),
    mode: 'all',
    defaultValues: {
      email: searchParams.get('email') || undefined,
      code: searchParams.get('code') || undefined,
    },
  });

  const handleOnResendCode = useCallback(async () => {
    const email = searchParams.get('email');
    if (email === null) {
      return;
    }
    await resendManagerVerificationEmail({
      variables: {
        input: {
          email,
        },
      },
    });
  }, [resendManagerVerificationEmail, searchParams]);

  const handleOnValid = useCallback(
    async ({ email, code }: VerifyEmailForm) => {
      const result = await verifyAndUpdateManagerEmail({
        variables: {
          input: {
            email,
            code,
          },
        },
      });
      if (result.data?.verifyAndUpdateManagerEmail) {
        navigate('/account-settings');
      }
    },
    [verifyAndUpdateManagerEmail, navigate],
  );

  return (
    <Container
      component="form"
      maxWidth="md"
      noValidate
      onSubmit={handleSubmit(handleOnValid)}>
      <Stack spacing={4}>
        <PageTitle>{t('manager:verifyUpdatedEmail.title')}</PageTitle>
        <Stack spacing={2}>
          <Controller<VerifyEmailForm, 'email'>
            name="email"
            control={control}
            render={ReadOnlyEmailField}
          />
          <Controller<VerifyEmailForm, 'code'>
            name="code"
            control={control}
            render={CodeField}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!isValid || verifyAndUpdateManagerEmailLoading}>
            {t('manager:verifyUpdatedEmail.submitButton')}
          </Button>
          <Button
            variant="outlined"
            disabled={resendManagerVerificationEmailLoading}
            onClick={handleOnResendCode}>
            {t('manager:verifyUpdatedEmail.resendCodeButton')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
