import { Button, IconButton, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import ProfileIconButton from './ProfileIconButton.tsx';
import FeedbackButton from './FeedbackButton.tsx';
import LogoName from './LogoName.tsx';

interface TopNavigationProps {
  isSetupCompleted: boolean;
  manager: {
    email: string;
    company: {
      name: string;
    };
  };
  onOpenDrawerClick: MouseEventHandler<HTMLButtonElement>;
}

export default function TopNavigation({
  isSetupCompleted,
  manager,
  onOpenDrawerClick,
}: TopNavigationProps) {
  return (
    <Stack flex={1} direction="row" justifyContent="space-between" spacing={2}>
      {isSetupCompleted ? (
        <Stack
          sx={{
            display: {
              md: 'none',
            },
          }}
          flex={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-start">
          <IconButton size="small" onClick={onOpenDrawerClick}>
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
        </Stack>
      ) : null}
      <Stack
        sx={{
          display: {
            md: isSetupCompleted ? 'none' : 'flex',
          },
        }}
        flex={1}
        direction="row"
        justifyContent={isSetupCompleted ? 'center' : 'flex-start'}
        alignItems="center">
        <Button component={Link} to="/">
          <LogoName height={30} />
        </Button>
      </Stack>
      <Stack
        flex={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}>
        <FeedbackButton size="small" />
        <ProfileIconButton email={manager.email} company={manager.company} />
      </Stack>
    </Stack>
  );
}
