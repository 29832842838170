import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-thin-svg-icons/faCircleCheck';
import { Link } from 'react-router-dom';

export interface CampaignGoalSubmissionCompletedProps {
  creatorHasCampaign: {
    campaign: {
      id: string;
    };
  };
}

export default function CampaignGoalSubmissionCompleted({
  creatorHasCampaign,
}: CampaignGoalSubmissionCompletedProps) {
  const { t } = useTranslation(['manager']);

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          p={4}
          minHeight={250}>
          <FontAwesomeIcon icon={faCircleCheck} size="6x" />
          <Typography variant="h4" align="center">
            {t('manager:creatorHasCampaignFlow.completed.title')}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {t('manager:creatorHasCampaignFlow.completed.content')}
          </Typography>
          <Button
            variant="text"
            component={Link}
            to={`/campaign-report/${creatorHasCampaign.campaign.id}`}>
            {t('manager:creatorHasCampaignFlow.completed.openReportButton')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
