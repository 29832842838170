import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { MessageSender } from '@social-garden/utils/types.ts';
import { GREEN, PURPLE } from '@social-garden/utils/colors.ts';
import ReplaceUrlWithLink from './ReplaceUrlWithLink.tsx';

export interface MessageListItemData {
  id: string;
  sender: MessageSender;
  text: string;
  createdAt: string;
}

interface MessageListItemProps {
  data: MessageListItemData;
}

export default function MessageListItem({ data }: MessageListItemProps) {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();

  const alignment = useMemo(() => {
    switch (data.sender) {
      case MessageSender.CREATOR:
        return 'flex-start';
      case MessageSender.BRAND:
        return 'flex-end';
      default:
        assertUnreachable(data.sender);
    }
  }, [data.sender]);

  const senderColor = useMemo(() => {
    switch (data.sender) {
      case MessageSender.CREATOR:
        return GREEN;
      case MessageSender.BRAND:
        return PURPLE;
      default:
        assertUnreachable(data.sender);
    }
  }, [data.sender]);

  return (
    <Stack
      alignSelf={alignment}
      alignItems={alignment}
      spacing={0.5}
      maxWidth="85%">
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? senderColor['100']
              : senderColor['900'],
        }}
        px={1.25}
        py={0.5}
        borderRadius={2}>
        <Typography
          sx={{
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
          }}>
          <ReplaceUrlWithLink
            sx={{
              color: (theme) => theme.palette.text.primary,
              textDecoration: 'underline',
            }}>
            {data.text}
          </ReplaceUrlWithLink>
        </Typography>
      </Box>
      <Typography variant="caption" color="text.secondary" px={1.25}>
        {new Date(data.createdAt).toLocaleString(resolvedLanguage, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Typography>
    </Stack>
  );
}
