import { Toolbar, AppBar as MuiAppBar } from '@mui/material';
import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { convertHexToRGBA } from '@social-garden/utils/helpers.ts';
import { OPACITY } from '@social-garden/utils/opacity.ts';
import TopNavigation from './TopNavigation.tsx';

interface AppBarProps {
  drawerWidth: number;
  toolbarVariant: 'regular' | 'dense';
  isSetupCompleted: boolean;
  manager: {
    email: string;
    company: {
      name: string;
    };
  };
  onOpenDrawerClick: MouseEventHandler<HTMLButtonElement>;
}

export default function AppBar({
  drawerWidth,
  toolbarVariant,
  isSetupCompleted,
  manager,
  onOpenDrawerClick,
}: AppBarProps) {
  const [isScrolledDown, setIsScrolledDown] = useState<boolean>(
    window.scrollY > 0,
  );

  const handleScroll = useCallback(() => {
    setIsScrolledDown(window.scrollY > 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <MuiAppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: (theme) => {
          if (isScrolledDown) {
            return convertHexToRGBA(
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
              OPACITY.high,
            );
          }
          return theme.palette.background.default;
        },
        width: {
          md: `calc(100% - ${drawerWidth}px)`,
        },
        ml: {
          md: `${drawerWidth}px`,
        },
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderBottomColor: (theme) =>
          isScrolledDown ? theme.palette.divider : 'transparent',
        transition:
          'background-color 250ms ease-in-out, border-bottom-color 250ms ease-in-out, backdrop-filter 250ms ease-in-out',
        backdropFilter: 'blur(10px)',
      }}>
      <Toolbar variant={toolbarVariant}>
        <TopNavigation
          isSetupCompleted={isSetupCompleted}
          manager={manager}
          onOpenDrawerClick={onOpenDrawerClick}
        />
      </Toolbar>
    </MuiAppBar>
  );
}
