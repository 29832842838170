import type {} from '@mui/x-data-grid/themeAugmentation';
import { ThemeOptions } from '@mui/material/styles';
import {
  BLACK,
  BLUE,
  GRAY,
  GREEN,
  ORANGE,
  PURPLE,
  RED,
  WHITE,
} from '@social-garden/utils/colors';
import { pageSizeOptions } from './PageSize.ts';

const defaultThemeOptions: ThemeOptions = {
  palette: {
    common: {
      black: BLACK,
      white: WHITE,
    },
    primary: {
      ...GREEN,
      main: GREEN['500'],
    },
    secondary: {
      ...PURPLE,
      main: PURPLE['500'],
    },
    info: {
      main: BLUE['500'],
      ...BLUE,
    },
    warning: {
      main: ORANGE['500'],
      ...ORANGE,
    },
    error: {
      main: RED['500'],
      ...RED,
    },
    success: {
      main: GREEN['500'],
      ...GREEN,
    },
    grey: {
      ...GRAY,
    },
  },
  components: {
    MuiDataGrid: {
      defaultProps: {
        density: 'comfortable',
        autoHeight: true,
        disableColumnSorting: true,
        disableColumnFilter: true,
        disableColumnMenu: true,
        disableRowSelectionOnClick: true,
        pageSizeOptions: [...pageSizeOptions],
      },
      styleOverrides: {
        root: {
          border: 0,
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'inherit',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        draggable: false,
        slotProps: {
          img: {
            draggable: false,
          },
        },
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
};

export const lightThemeOptions: ThemeOptions = {
  ...defaultThemeOptions,
  palette: {
    ...defaultThemeOptions.palette,
    mode: 'light',
    background: {
      paper: WHITE,
      default: GRAY['50'],
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  ...defaultThemeOptions,
  palette: {
    ...defaultThemeOptions.palette,
    mode: 'dark',
    background: {
      paper: BLACK,
      default: GRAY['950'],
    },
  },
};
