import { Stack, StackProps, Typography } from '@mui/material';
import { Download } from '@social-garden/utils/types.ts';
import { useMemo } from 'react';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import BrandLogo from './BrandLogo.tsx';

interface BrandProps extends Omit<StackProps, 'children'> {
  name: string;
  logo: Download;
  size?: 'small' | 'medium' | 'large';
}

export default function Brand({
  name,
  logo,
  size = 'medium',
  ...rest
}: BrandProps) {
  const logoSize = useMemo(() => {
    switch (size) {
      case 'small':
        return 24;
      case 'medium':
        return 34;
      case 'large':
        return 44;
      default:
        assertUnreachable(size);
    }
  }, [size]);

  const nameSize = useMemo(() => {
    switch (size) {
      case 'small':
        return 16;
      case 'medium':
        return 22;
      case 'large':
        return 28;
      default:
        assertUnreachable(size);
    }
  }, [size]);

  return (
    <Stack direction="row" spacing={0.8} alignItems="center" {...rest}>
      <BrandLogo
        sx={{
          width: logoSize,
          height: logoSize,
        }}
        src={logo}
        alt={name}
      />
      <Typography
        fontSize={nameSize}
        lineHeight={1.2}
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap">
        {name}
      </Typography>
    </Stack>
  );
}
