import { useTranslation } from 'react-i18next';
import { Grid2, Stack, Typography, Chip, Tooltip } from '@mui/material';
import { formatEuroCents } from '@social-garden/utils/helpers.ts';
import { CreatorHasCampaignStatus } from '@social-garden/utils/types.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { ORANGE } from '@social-garden/utils/colors.ts';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { faChartPie } from '@fortawesome/pro-regular-svg-icons/faChartPie';
import { faMessage } from '@fortawesome/pro-regular-svg-icons/faMessage';
import useCreatorHasCampaignStatus from '../../hooks/useCreatorHasCampaignStatus.ts';
import ProfilePicture from '../../components/ProfilePicture.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import ReplaceUrlWithLink from '../../components/ReplaceUrlWithLink.tsx';
import { InstagramChannelInsightsData } from '../../components/InstagramChannelInsights.tsx';
import ChannelStatistics, {
  InstagramChannelStatistics,
  TiktokChannelStatistics,
  YoutubeChannelStatistics,
} from '../../components/ChannelStatistics.tsx';
import InstagramChannelInsightsDialog from '../../components/InstagramChannelInsightsDialog.tsx';
import HeaderActionButton from '../../components/HeaderActionButton.tsx';

interface CreatorHasCampaignHeaderProps {
  creatorHasCampaign: {
    id: string;
    creator: {
      username: string;
      description?: string | null;
      isUnreliable: boolean;
      profilePicture?: {
        uri: string;
        hash?: string | null;
      } | null;
    };
    campaign: {
      id: string;
      name: string;
    };
    price: number;
    status: CreatorHasCampaignStatus;
    channel?: {
      channelable:
        | (InstagramChannelStatistics & InstagramChannelInsightsData)
        | TiktokChannelStatistics
        | YoutubeChannelStatistics;
    } | null;
  };
}

export default function CreatorHasCampaignHeader({
  creatorHasCampaign,
}: CreatorHasCampaignHeaderProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common', 'manager']);
  const navigate = useNavigate();
  const { getCreatorHasCampaignStatusName, getCreatorHasCampaignStatusColor } =
    useCreatorHasCampaignStatus();
  const [
    instagramChannelInsightsDialogOpen,
    setInstagramChannelInsightsDialogOpen,
  ] = useState<boolean>(false);

  const handleOnNavigateToCampaign = useCallback(() => {
    navigate(`/campaign-details/${creatorHasCampaign.campaign.id}`);
  }, [navigate, creatorHasCampaign.campaign.id]);

  const handleOnNavigateToMessages = useCallback(() => {
    navigate(`/messages/${creatorHasCampaign.id}`);
  }, [navigate, creatorHasCampaign.id]);

  const handleOnOpenInstagramChannelInsightsDialog = useCallback(() => {
    setInstagramChannelInsightsDialogOpen(true);
  }, []);

  const handleOnCloseInstagramChannelInsightsDialog = useCallback(() => {
    setInstagramChannelInsightsDialogOpen(false);
  }, []);

  return (
    <Grid2 container spacing={3}>
      <Grid2
        size={{
          xs: 12,
          sm: 'auto',
        }}>
        <ProfilePicture
          username={creatorHasCampaign.creator.username}
          src={creatorHasCampaign.creator.profilePicture}
          size={150}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 'grow',
        }}>
        <Stack spacing={0.5}>
          <Stack direction="row" alignItems="flex-start" spacing={2}>
            <PageTitle>{creatorHasCampaign.creator.username}</PageTitle>
            {creatorHasCampaign.creator.isUnreliable ? (
              <Tooltip title={t('manager:creator.isUnreliable')}>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  size="2xl"
                  color={ORANGE['500']}
                />
              </Tooltip>
            ) : null}
          </Stack>
          {creatorHasCampaign.channel ? (
            <ChannelStatistics data={creatorHasCampaign.channel.channelable} />
          ) : (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <FontAwesomeIcon icon={faBullhorn} size="xl" />
              <Typography fontSize={20}>
                {t('manager:channel.missing')}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 'auto',
        }}>
        <HeaderActionButton
          actions={[
            {
              primary: true,
              icon: faMessage,
              label: t('manager:creatorHasCampaign.messages'),
              onClick: handleOnNavigateToMessages,
            },
            {
              icon: faChartPie,
              label: t('manager:channelInsights.button'),
              hidden:
                creatorHasCampaign.channel?.channelable.__typename !==
                'InstagramChannel',
              onClick: handleOnOpenInstagramChannelInsightsDialog,
            },
          ]}
          variant="contained"
          size="large"
        />
      </Grid2>
      {creatorHasCampaign.creator.description ? (
        <Grid2 size={12}>
          <Stack maxWidth={600}>
            <Typography
              style={{
                whiteSpace: 'pre-line',
              }}>
              <ReplaceUrlWithLink>
                {creatorHasCampaign.creator.description}
              </ReplaceUrlWithLink>
            </Typography>
          </Stack>
        </Grid2>
      ) : null}
      <Grid2 container size={12} spacing={2}>
        <Grid2 size="auto">
          <Chip
            label={getCreatorHasCampaignStatusName(creatorHasCampaign.status)}
            variant="filled"
            color={getCreatorHasCampaignStatusColor(creatorHasCampaign.status)}
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            label={`${t('common:price')}: ${formatEuroCents(creatorHasCampaign.price, resolvedLanguage)}`}
            variant="filled"
          />
        </Grid2>
        <Grid2 size="auto">
          <Chip
            sx={{
              textDecoration: 'underline',
            }}
            label={`${t('manager:creatorHasCampaign.campaign.name.label')}: ${creatorHasCampaign.campaign.name}`}
            variant="filled"
            clickable
            onClick={handleOnNavigateToCampaign}
          />
        </Grid2>
      </Grid2>
      {creatorHasCampaign.channel?.channelable.__typename ===
      'InstagramChannel' ? (
        <InstagramChannelInsightsDialog
          open={instagramChannelInsightsDialogOpen}
          data={creatorHasCampaign.channel.channelable}
          onClose={handleOnCloseInstagramChannelInsightsDialog}
        />
      ) : null}
    </Grid2>
  );
}
