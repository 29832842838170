import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import {
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confirm, { ConfirmOptions } from './Confirm.tsx';

interface Action {
  icon: IconProp;
  label: string;
  primary?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  confirm?: ConfirmOptions;
  onClick: MouseEventHandler;
}

interface HeaderActionButtonProps extends Omit<ButtonGroupProps, 'children'> {
  actions: Action[];
}

export default function HeaderActionButton({
  actions,
  ...rest
}: HeaderActionButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const primaryAction = useMemo(
    () => actions.find(({ primary, hidden }) => primary === true && !hidden),
    [actions],
  );

  const primaryButton = useMemo(() => {
    if (primaryAction === undefined) {
      return null;
    }
    const button = (
      <Button
        hidden={primaryAction.hidden}
        disabled={primaryAction.disabled}
        onClick={primaryAction.onClick}>
        {primaryAction.label}
      </Button>
    );
    if (primaryAction.confirm) {
      return <Confirm {...primaryAction.confirm}>{button}</Confirm>;
    }
    return button;
  }, [primaryAction]);

  const menuActions = useMemo(
    () => actions.filter(({ primary, hidden }) => !primary && !hidden),
    [actions],
  );

  const handleOpen = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnMenuItemClick = useCallback(
    (onClick: MouseEventHandler) => (event: MouseEvent) => {
      handleClose();
      onClick(event);
    },
    [handleClose],
  );

  if (primaryButton === null && menuActions.length === 0) {
    return null;
  }

  return (
    <>
      <ButtonGroup {...rest}>
        {primaryButton}
        {menuActions.length > 0 ? (
          <Button
            size="small"
            aria-controls={anchorEl ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
            onClick={handleOpen}>
            <ArrowDropDownIcon />
          </Button>
        ) : null}
      </ButtonGroup>
      {menuActions.length > 0 ? (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {menuActions.map(
            ({ label, icon, hidden, disabled, confirm, onClick }) => {
              const item = (
                <MenuItem
                  key={label}
                  hidden={hidden}
                  disabled={disabled}
                  onClick={handleOnMenuItemClick(onClick)}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={icon} />
                  </ListItemIcon>
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              );
              if (confirm) {
                return (
                  <Confirm key={label} {...confirm}>
                    {item}
                  </Confirm>
                );
              }
              return item;
            },
          )}
        </Menu>
      ) : null}
    </>
  );
}
