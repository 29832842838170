import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { getToken } from '../utils/authToken.ts';

export function managerLoader({ request }: LoaderFunctionArgs) {
  const token = getToken();
  if (token) {
    return null;
  }
  const params = new URLSearchParams();
  params.set('from', new URL(request.url).pathname);
  return redirect(`/login?${params.toString()}`);
}

export function authLoader() {
  const token = getToken();
  if (token) {
    return redirect('/');
  }
  return null;
}
