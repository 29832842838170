import { RouteObject } from 'react-router-dom';
import RootLayout from '../layouts/RootLayout.tsx';
import RouteError from './RouteError.tsx';
import AuthRoutes from './AuthRoutes.tsx';
import ManagerRoutes from './ManagerRoutes.tsx';
import Impersonate from '../pages/Impersonate/Impersonate.tsx';
import NotFound from '../pages/NotFound/NotFound.tsx';

const RootRoutes: RouteObject = {
  path: '/',
  element: <RootLayout />,
  errorElement: <RouteError />,
  children: [
    AuthRoutes,
    ManagerRoutes,
    {
      path: 'impersonate/:token',
      element: <Impersonate />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ],
};

export default RootRoutes;
