import { Card, CardContent, Stack, Tooltip, Typography } from '@mui/material';

interface ReportStatCardProps {
  label: string;
  value: string | number;
  description?: string;
}

export default function ReportStatisticCard({
  label,
  value,
  description,
}: ReportStatCardProps) {
  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: 215,
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
      variant="outlined"
      elevation={0}>
      <CardContent>
        <Tooltip title={description}>
          <Stack>
            <Typography variant="overline">{label}</Typography>
            <Typography variant="h4">{value}</Typography>
          </Stack>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
