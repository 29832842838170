import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import zodEN from 'zod-i18n-map/locales/en/zod.json';
import zodDE from 'zod-i18n-map/locales/de/zod.json';
import commonEN from '@social-garden/locales/en/common.json';
import commonDE from '@social-garden/locales/de/common.json';
import managerEN from '@social-garden/locales/en/manager.json';
import managerDE from '@social-garden/locales/de/manager.json';

export const DEFAULT_NS = 'common';

export const RESOURCES = {
  en: {
    manager: managerEN,
    common: commonEN,
    zod: zodEN,
  },
  de: {
    manager: managerDE,
    common: commonDE,
    zod: zodDE,
  },
} as const;

export function initI18n() {
  return use(initReactI18next).init({
    resources: RESOURCES,
    ns: ['common', 'manager', 'zod'],
    defaultNS: DEFAULT_NS,
    lng: navigator.language,
    fallbackLng: 'en',
    compatibilityJSON: 'v3',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    returnNull: false,
  });
}
