import {
  Button,
  IconButton,
  Stack,
  Toolbar,
  Drawer as MuiDrawer,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { GREEN, WHITE } from '@social-garden/utils/colors.ts';
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import SideNavigation from './SideNavigation.tsx';
import LogoName from './LogoName.tsx';

interface DrawerProps {
  drawerWidth: number;
  toolbarVariant: 'regular' | 'dense';
  mobileOpen: boolean;
  onCloseDrawerClick: MouseEventHandler<HTMLButtonElement>;
}

export default function Drawer({
  drawerWidth,
  toolbarVariant,
  mobileOpen,
  onCloseDrawerClick,
}: DrawerProps) {
  return (
    <Stack
      component="nav"
      sx={{
        width: {
          md: drawerWidth,
        },
      }}>
      <MuiDrawer
        sx={{
          display: {
            md: 'none',
          },
        }}
        PaperProps={{
          sx: {
            width: drawerWidth,
            border: 0,
            color: WHITE,
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? GREEN['800'] : GREEN['950'],
          },
        }}
        variant="temporary"
        elevation={0}
        open={mobileOpen}
        onClose={onCloseDrawerClick}>
        <Toolbar
          sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
          variant={toolbarVariant}>
          <IconButton color="inherit" onClick={onCloseDrawerClick}>
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Toolbar>
        <SideNavigation onListItemClick={onCloseDrawerClick} />
      </MuiDrawer>
      <MuiDrawer
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
        PaperProps={{
          sx: {
            width: drawerWidth,
            border: 0,
            color: WHITE,
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? GREEN['800'] : GREEN['950'],
          },
        }}
        variant="permanent"
        elevation={0}
        open>
        <Toolbar
          sx={{ alignItems: 'center', justifyContent: 'center' }}
          variant={toolbarVariant}>
          <Button component={Link} to="/">
            <LogoName fill={WHITE} height={30} />
          </Button>
        </Toolbar>
        <SideNavigation />
      </MuiDrawer>
    </Stack>
  );
}
