export function assertUnreachable(x: never): never {
  return x;
}

export function limitCount(count: number, limit = 99) {
  if (count > limit) {
    return `${limit}+`;
  }
  return count.toString(10);
}

export function convertHexToRGBA(hexCode: string, opacity = 1) {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  let opacityValue = opacity;
  if (opacityValue > 1 && opacityValue <= 100) {
    opacityValue /= 100;
  }

  return `rgba(${r},${g},${b},${opacityValue})`;
}

export function formatBytes(
  bytes: number,
  locales: Intl.LocalesArgument,
  maximumFractionDigits = 2,
) {
  const k = 1024;
  const sizes = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ] as const;

  if (bytes === 0) {
    return `0 ${sizes[0]}`;
  }

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const value = bytes / Math.pow(k, i);

  return `${value.toLocaleString(locales, {
    maximumFractionDigits,
  })} ${sizes[i]}`;
}

export function withHttps(url: string) {
  const value = url.trim();
  if (!/^https?:\/\//i.test(value)) {
    return `https://${value}`;
  }
  return value;
}

export function idKeyExtractor({ id }: { id: string }) {
  return id;
}

export function getShortLanguageCode(locale: string) {
  const index = locale.indexOf('-');
  if (index === -1) {
    return locale;
  }
  return locale.substring(0, index);
}

export function randomIntegerFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export async function setTimeoutAsync(ms: number) {
  await new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function formatEuroCents(cent: number, locales: Intl.LocalesArgument) {
  return (cent / 100).toLocaleString(locales, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function isInt(n: number) {
  return n % 1 === 0;
}

export function getSeparator(
  locale: string,
  separatorType: Intl.NumberFormatPartTypes,
) {
  const numberWithGroupAndDecimalSeparator = 1000.1;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find((part) => part.type === separatorType)?.value;
}

export function abbreviateCount(
  count: number,
  locales: Intl.LocalesArgument,
  maximumFractionDigits = 2,
) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => count >= i.value);
  if (item === undefined) {
    return count.toLocaleString(locales, {
      maximumFractionDigits,
    });
  }
  return (
    (count / item.value)
      .toLocaleString(locales, {
        maximumFractionDigits,
      })
      .replace(rx, '$1') + item.symbol
  );
}

export function getUriWithHash({
  uri,
  hash,
}: {
  uri?: string;
  hash?: string | null;
}) {
  if (uri === undefined) {
    return undefined;
  }
  if (!hash) {
    return uri;
  }
  return `${uri}?hash=${hash}`;
}

export function getGoogleMapsSearchUrl(query: string) {
  return `https://maps.google.com/?q=${encodeURIComponent(query)}`;
}

export function onChangeHelper<T>(onChange: (...event: any[]) => void) {
  return function (_: any, value: T) {
    return onChange(value);
  };
}

export function getInitials(name: string) {
  const matches = name.match(/[a-zA-Z]{2}/);
  if (matches && !!matches[0].length) {
    return matches[0].toUpperCase();
  }
  return name.substring(0, 2).toUpperCase();
}

export function round(number: number, decimalPlaces = 0) {
  const p = Math.pow(10, decimalPlaces);
  const n = number * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
}

export function formatPercentage(
  percentage: number,
  locales: Intl.LocalesArgument,
  maximumFractionDigits = 2,
) {
  return `${percentage.toLocaleString(locales, {
    maximumFractionDigits,
  })}%`;
}

export function getTiktokAuthUrl({
  clientKey,
  scopes,
  responseType,
  redirectUri,
  state,
}: {
  clientKey: string;
  scopes: string[];
  responseType: 'code';
  redirectUri: string;
  state: string;
}) {
  const url = new URL('https://www.tiktok.com/v2/auth/authorize');
  url.searchParams.append('client_key', clientKey);
  url.searchParams.append('scope', scopes.join(','));
  url.searchParams.append('response_type', responseType);
  url.searchParams.append('redirect_uri', redirectUri);
  url.searchParams.append('state', state);
  return url;
}

export function getContrastColor(hexColor: string) {
  if (hexColor.slice(0, 1) === '#') {
    hexColor = hexColor.slice(1);
  }

  if (hexColor.length === 3) {
    hexColor = hexColor
      .split('')
      .map(function (hex) {
        return hex + hex;
      })
      .join('');
  }

  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? '#000000' : '#ffffff';
}
