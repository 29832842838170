import { IconButton, Stack, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons/faPaperPlaneTop';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  createMessageDocument,
  messagesDocument,
} from '@social-garden/api/documents/message.ts';
import {
  SendMessageFieldValues,
  SendMessageSchema,
} from '../../constants/ValidationSchema.ts';
import { FormFieldProps } from '../../utils/types.ts';

interface MessagesFooterProps {
  creatorHasCampaignId: string;
}

export default function MessagesFooter({
  creatorHasCampaignId,
}: MessagesFooterProps) {
  const { t } = useTranslation(['common']);

  const [createMessage] = useMutation(createMessageDocument, {
    refetchQueries: [
      {
        query: messagesDocument,
        variables: {
          creatorHasCampaignId,
          first: 25,
          page: 1,
        },
      },
    ],
  });

  const {
    control,
    reset,
    formState: { isValid },
    handleSubmit,
  } = useForm<SendMessageFieldValues>({
    resolver: zodResolver(SendMessageSchema),
    mode: 'all',
    defaultValues: {
      text: '',
    },
  });

  const renderTextMessageField = useCallback(
    ({
      field: { name, value, onChange, onBlur },
      fieldState: { invalid },
    }: FormFieldProps<SendMessageFieldValues, 'text'>) => (
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder={t('common:message')}
        multiline
        minRows={1}
        maxRows={4}
        margin="none"
        name={name}
        value={value}
        error={value.length > 0 ? invalid : false}
        onChange={onChange}
        onBlur={onBlur}
      />
    ),
    [t],
  );

  const handleOnValid = useCallback(
    async ({ text }: SendMessageFieldValues) => {
      reset();
      await createMessage({
        variables: {
          input: {
            creatorHasCampaignId,
            text,
          },
        },
      });
    },
    [createMessage, creatorHasCampaignId, reset],
  );

  return (
    <Stack
      direction="row"
      spacing={1}
      component="form"
      alignItems="center"
      justifyContent="space-between"
      noValidate
      onSubmit={handleSubmit(handleOnValid)}>
      <Controller<SendMessageFieldValues, 'text'>
        name="text"
        control={control}
        render={renderTextMessageField}
      />
      <IconButton type="submit" disabled={!isValid}>
        <FontAwesomeIcon icon={faPaperPlaneTop} size="sm" />
      </IconButton>
    </Stack>
  );
}
