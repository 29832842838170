import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import './index.css';
import { initI18n } from './utils/i18n.ts';
import { initSentry } from './utils/sentry.ts';
import App from './App.tsx';
import { persistor } from './utils/apolloInMemoryCache.ts';

initSentry();
await Promise.all([initI18n(), persistor.restore()]);

const rootElement = document.getElementById('root');
if (rootElement) {
  createRoot(rootElement).render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
