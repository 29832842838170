import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldProps } from '../utils/types.ts';
import InfoIcon from './InfoIcon.tsx';

export default function IncludeContentFileOnSubmissionField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field: { name, value, onChange, onBlur },
}: FormFieldProps<TFieldValues, TName>) {
  const { t } = useTranslation(['manager']);
  return (
    <FormControlLabel
      label={
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>
            {t('manager:form.field.includeContentFileOnSubmission.label')}
          </Typography>
          <InfoIcon
            title={t(
              'manager:form.field.includeContentFileOnSubmission.description',
            )}
          />
        </Stack>
      }
      control={
        <Checkbox
          checked={Boolean(value)}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
        />
      }
    />
  );
}
