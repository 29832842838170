import { LinearProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

interface BrandUsageProgressProps {
  current: number;
  max: number | null;
}

export default function BrandUsageProgress({
  current,
  max,
}: BrandUsageProgressProps) {
  const { t } = useTranslation(['manager']);

  const progressValue = useMemo(() => {
    if (max === null) {
      return 0;
    }
    if (max === 0 || current >= max) {
      return 100;
    }
    return (current / max) * 100;
  }, [current, max]);

  return (
    <Stack>
      <Typography>
        {t('manager:company.usage.brand', {
          current,
          max: max === null ? '∞' : max,
        })}
      </Typography>
      <LinearProgress
        sx={{
          borderRadius: 5,
          height: 5,
        }}
        variant="determinate"
        color="secondary"
        value={progressValue}
      />
    </Stack>
  );
}
