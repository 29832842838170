const AUTH_TOKEN_STORAGE_KEY = 'manager-social-garden-auth-token';

export function getToken() {
  return localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
}

export function setToken(value: string) {
  return localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, value);
}

export function removeToken() {
  return localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
}
