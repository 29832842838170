import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { updateManagerLocaleDocument } from '@social-garden/api/documents/manager.ts';
import { useCallback } from 'react';
import useColorMode from '../../hooks/useColorMode.ts';
import Section from '../../components/Section.tsx';
import { ColorMode } from '../../contexts/colorModeContext.ts';

export default function GeneralSettings() {
  const {
    t,
    i18n: { changeLanguage, resolvedLanguage, languages },
  } = useTranslation(['common', 'manager']);

  const { mode, setMode } = useColorMode();

  const [updateManagerLocale, { loading }] = useMutation(
    updateManagerLocaleDocument,
  );

  const handleOnLanguageChange = useCallback(
    async (event: SelectChangeEvent<string>) => {
      await Promise.all([
        changeLanguage(event.target.value),
        updateManagerLocale({
          variables: {
            input: {
              locale: event.target.value,
            },
          },
        }),
      ]);
    },
    [changeLanguage, updateManagerLocale],
  );

  const handleOnChange = useCallback(
    (event: SelectChangeEvent<ColorMode>) => {
      setMode(event.target.value as ColorMode);
    },
    [setMode],
  );

  return (
    <Section header={t('manager:settings.header.general')}>
      <FormControl fullWidth>
        <InputLabel>{t('manager:settings.language')}</InputLabel>
        <Select
          variant="outlined"
          label={t('manager:settings.language')}
          disabled={loading}
          value={resolvedLanguage ?? languages[0]}
          onChange={handleOnLanguageChange}>
          <MenuItem value="en">{t('common:locale.en')}</MenuItem>
          <MenuItem value="de">{t('common:locale.de')}</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t('manager:settings.theme')}</InputLabel>
        <Select<ColorMode>
          variant="outlined"
          label={t('manager:settings.theme')}
          value={mode}
          onChange={handleOnChange}>
          <MenuItem value="system">{t('common:theme.system')}</MenuItem>
          <MenuItem value="light">{t('common:theme.light')}</MenuItem>
          <MenuItem value="dark">{t('common:theme.dark')}</MenuItem>
        </Select>
      </FormControl>
    </Section>
  );
}
