import { useSuspenseQuery } from '@apollo/client';
import { Container } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { creatorHasCampaignDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { useEffect } from 'react';
import CreatorHasCampaignHeader from './CreatorHasCampaignHeader.tsx';
import CreatorHasCampaignFlow from './CreatorHasCampaignFlow.tsx';

export default function CreatorHasCampaign() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    data: { creatorHasCampaign },
  } = useSuspenseQuery(creatorHasCampaignDocument, {
    variables: {
      id: id ?? '',
    },
  });

  useEffect(() => {
    if (id && searchParams.has('tab-index', '1')) {
      navigate(`/messages/${id}`);
    }
  }, [navigate, id, searchParams]);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}>
      <CreatorHasCampaignHeader creatorHasCampaign={creatorHasCampaign} />
      <CreatorHasCampaignFlow creatorHasCampaign={creatorHasCampaign} />
    </Container>
  );
}
